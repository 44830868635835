import { Action } from 'redux';

export interface PaginationData {
    /**
     * The number of entries in the page being returned.
     */
    pageSize: number;

    /**
     * The maximum number of pages that could be fetched with the criteria specified.
     */
    pageCount: number;

    /**
     * The total count of entries being returned across all pages.
     */
    totalCount: number;

    /**
     * The integer value/index of the page being returned.
     */
    currentPage: number;
}

export interface Paginable {
    /**
     * The object holding the pagination data.
     */
    pagination?: PaginationData;
    /**
     * Whether the user is fetching a new page
     */
    isPaginating: boolean;
}

export enum SortOption {
    RELEVANCE = '',
    LEGAL_DATE = 'legalEndDate,desc',
    LAST_ADDED = 'publicationStartDate,desc',
    CYCLES_LAST_ADDED = 'publicationDate,desc',
    LAST_CREATED = 'createdAt,desc',
    ALPHABETICALLY_DESC = 'title,desc',
    ALPHABETICALLY_ASC = 'title,asc',
    ORDERED_DATE_DESC = 'orderedAt,desc',
}

export interface Sortable {
    sort?: SortOption;
    isSorting: boolean;
}

export interface SortingAction<A> extends Action<A> {
    sort: SortOption;
}

/*
FIXME: ideally, properties here should not be optionnal.
We should have a PaginatedFetchAction and a PaginatedResponseAction.
But then we'd have to refactor all the actions we currently have, and
I don't have time for this currently.
*/
export interface PaginationAction<A> extends Action<A> {
    pagination?: PaginationData;
    requestedPage?: number;
    pageSize?: number;
}
