import find from 'lodash/find';

export default class Breakpoint {
    public static MOBILE: Breakpoint = new Breakpoint('mobile');
    public static TABLET: Breakpoint = new Breakpoint('tablet');
    public static DESKTOP: Breakpoint = new Breakpoint('desktop');
    public static WIDESCREEN: Breakpoint = new Breakpoint('widescreen');
    public static FULL_HD: Breakpoint = new Breakpoint('full-hd');

    // Null Object Design Pattern
    public static UNDEFINED: Breakpoint = new Breakpoint(undefined);

    // This comes from src/pages/App.scss
    private label: string | undefined;

    private constructor(label: string | undefined) {
        this.label = label;
    }

    /**
     * When called, returns the current breakpoint (aka device) applied.
     * Examples of use in TypeScript code :
     * if (Breakpoint.getCurrent() === Breakpoint.MOBILE) { ... }
     * if (Breakpoint.getCurrent().isSmallerOrEqualTo(Breakpoint.DESKTOP)) { ... }
     * if (Breakpoint.getCurrent().isWiderOrEqualTo(Breakpoint.WIDESCREEN)) { ... }
     * ...
     */
    public static getCurrent(): Breakpoint {
        if (typeof window !== 'object') {
            return Breakpoint.UNDEFINED;
        }
        const label: string | null = window.getComputedStyle(document.body, ':before').content;
        if (label != null) {
            return Breakpoint.getBreakpointByLabel(label.replace(/\"/g, ''));
        } else {
            return Breakpoint.UNDEFINED;
        }
    }

    private static ALL(): Breakpoint[] {
        // This must be ordered !
        return [Breakpoint.MOBILE, Breakpoint.TABLET, Breakpoint.DESKTOP, Breakpoint.WIDESCREEN, Breakpoint.FULL_HD];
    }

    private static getBreakpointByLabel(label: string): Breakpoint {
        return (
            find(Breakpoint.ALL(), (breakpoint: Breakpoint): boolean => {
                return label === breakpoint.label;
            }) || Breakpoint.UNDEFINED
        );
    }

    public isSmallerOrEqualTo(breakpoint: Breakpoint): boolean {
        return this.compareTo(breakpoint, (i, j): boolean => i <= j);
    }

    public isSmallerThan(breakpoint: Breakpoint): boolean {
        return this.compareTo(breakpoint, (i, j): boolean => i < j);
    }

    public isWiderOrEqualTo(breakpoint: Breakpoint): boolean {
        return this.compareTo(breakpoint, (i, j): boolean => i >= j);
    }

    public isWiderThan(breakpoint: Breakpoint): boolean {
        return this.compareTo(breakpoint, (i, j): boolean => i > j);
    }

    private compareTo(breakpoint: Breakpoint, comparator: (index1: number, index2: number) => boolean): boolean {
        if (this === Breakpoint.UNDEFINED || breakpoint === Breakpoint.UNDEFINED) {
            return false;
        }
        const idx1: number = Breakpoint.ALL().indexOf(this);
        const idx2: number = Breakpoint.ALL().indexOf(breakpoint);
        if (idx1 === -1 || idx2 === -1) {
            return false;
        }
        return comparator(idx1, idx2);
    }
}
