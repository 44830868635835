import { ReviewsActionType } from './ReviewsActionType';
import { FetchAction } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';
import { Review } from '../../domain/community';
import { Action } from 'redux';

export type ReviewsAction = Action<ReviewsActionType>;
export type ReviewsFetchAction = FetchAction<ReviewsActionType, Review[]>;
export interface ReviewUpdateAction extends ReviewsAction {
    payload: Review | RequestError;
}

export function createFetchLastReviewsAction(): ReviewsAction {
    return {
        type: ReviewsActionType.FETCH_LAST_REVIEWS,
    };
}

export function createFetchLastReviewsSuccessAction(reviews: Review[]): ReviewsFetchAction {
    return {
        type: ReviewsActionType.FETCH_LAST_REVIEWS_SUCCESS,
        payload: reviews,
    };
}

export function createFetchLastReviewsErrorAction(error: RequestError): ReviewsFetchAction {
    return {
        type: ReviewsActionType.FETCH_LAST_REVIEWS_ERROR,
        payload: error,
    };
}

export function createFetchUserReviewsAction(): ReviewsAction {
    return {
        type: ReviewsActionType.FETCH_USER_REVIEWS,
    };
}

export function createFetchUserReviewsSuccessAction(reviews: Review[]): ReviewsFetchAction {
    return {
        type: ReviewsActionType.FETCH_USER_REVIEWS_SUCCESS,
        payload: reviews,
    };
}

export function createFetchUserReviewsErrorAction(error: RequestError): ReviewsFetchAction {
    return {
        type: ReviewsActionType.FETCH_USER_REVIEWS_ERROR,
        payload: error,
    };
}

export function createAddReviewAction(review: Review): ReviewUpdateAction {
    return {
        type: ReviewsActionType.ADD_REVIEW,
        payload: review,
    };
}

export function createUpdateReviewAction(review: Review): ReviewUpdateAction {
    return {
        type: ReviewsActionType.UPDATE_REVIEW,
        payload: review,
    };
}

export function createEditReviewAction(review: Review): ReviewUpdateAction {
    return {
        type: ReviewsActionType.EDIT_REVIEW,
        payload: review,
    };
}

export function createAddReviewSuccessAction(review: Review): ReviewUpdateAction {
    return {
        type: ReviewsActionType.ADD_REVIEW_SUCCESS,
        payload: review,
    };
}

export function createResetReviewAddedSuccessAction(): ReviewsAction {
    return { type: ReviewsActionType.RESET_ADD_REVIEW_SUCCESS };
}

export function createAddReviewErrorAction(error: RequestError): ReviewUpdateAction {
    return {
        type: ReviewsActionType.ADD_REVIEW_ERROR,
        payload: error,
    };
}

export function createDeleteReviewAction(review: Review): ReviewUpdateAction {
    return {
        type: ReviewsActionType.DELETE_REVIEW,
        payload: review,
    };
}

export function createDeleteReviewSuccessAction(review: Review): ReviewUpdateAction {
    return {
        type: ReviewsActionType.DELETE_REVIEW_SUCCESS,
        payload: review,
    };
}
