import { takeLatest, call, put, select } from 'redux-saga/effects';
import { ReviewsActionType } from './ReviewsActionType';
import { SagaIterator } from 'redux-saga';
import { callApi } from '../../sagas/APICallSaga';
import { AxiosResponse } from 'axios';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import filter from 'lodash/filter';
import {
    ReviewsFetchAction,
    ReviewUpdateAction,
    createAddReviewErrorAction,
    createAddReviewSuccessAction,
    createResetReviewAddedSuccessAction,
    createFetchUserReviewsSuccessAction,
    createFetchUserReviewsErrorAction,
    createDeleteReviewSuccessAction,
    createFetchLastReviewsSuccessAction,
    createFetchLastReviewsErrorAction,
} from './ReviewsActions';
import { Review } from '../../domain/community';
import { AppState } from '../../store/reducers';
import { createFetchMovieSuccessAction, MovieStateData } from '../movie';
import { createToggleConfirmReviewDeletionModalAction } from '../UI';
import { SortOption } from '../../domain/listings';
import { ReviewsState } from './';

function* fetchLastReviews(): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'reviews',
            params: { sort: SortOption.LAST_CREATED },
        },
        onSuccess: (res: AxiosResponse): ReviewsFetchAction =>
            createFetchLastReviewsSuccessAction(get(res.data, '_embedded.userReviews', [])),
        onError: createFetchLastReviewsErrorAction,
    });
}

function* fetchUserReviews(): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: 'users/reviews',
        },
        onSuccess: (res: AxiosResponse): ReviewsFetchAction =>
            createFetchUserReviewsSuccessAction(get(res.data, '_embedded.userReviews', [])),
        onError: createFetchUserReviewsErrorAction,
    });
}

function* addReview(action: ReviewUpdateAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users/reviews`,
            method: 'POST',
            body: action.payload,
        },
        onSuccess: (res: AxiosResponse<Review>): ReviewUpdateAction => createAddReviewSuccessAction(res.data),
        onError: createAddReviewErrorAction,
    });
}

function* updateReview(action: ReviewUpdateAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users/reviews/${(action.payload as Review).id}`,
            method: 'PUT',
            body: action.payload,
        },
        onSuccess: (res: AxiosResponse<Review>): ReviewUpdateAction => createAddReviewSuccessAction(res.data),
        onError: createAddReviewErrorAction,
    });
}

function* onReviewAddedSuccess(action: ReviewUpdateAction): SagaIterator {
    yield put(createResetReviewAddedSuccessAction());
    const movie: MovieStateData | undefined = yield select(
        (state: AppState): MovieStateData | undefined => state.movie.data
    );
    if (movie) {
        const payload = action.payload as Review;
        if (movie.reviews && movie.reviews.length > 0) {
            const updatedReviewIndex = findIndex(movie.reviews, (review: Review): boolean => review.id === payload.id);
            if (updatedReviewIndex !== -1) {
                const updatedReviews: Review[] = Object.assign([], movie.reviews, {
                    [updatedReviewIndex]: payload,
                });
                yield put(createFetchMovieSuccessAction({ ...movie, reviews: updatedReviews }));
            } else {
                yield put(createFetchMovieSuccessAction({ ...movie, reviews: [payload, ...movie.reviews] }));
            }
        } else {
            yield put(createFetchMovieSuccessAction({ ...movie, reviews: [payload] }));
        }
    }

    const reviews: Review[] | undefined = yield select((state: AppState): Review[] | undefined => state.reviews.data);
    if (reviews) {
        const payload = action.payload as Review;
        const updatedReviewIndex = findIndex(reviews, (review: Review): boolean => review.id === payload.id);
        if (updatedReviewIndex !== -1) {
            const updatedReviews: Review[] = Object.assign([], reviews, {
                [updatedReviewIndex]: payload,
            });
            yield put(createFetchUserReviewsSuccessAction(updatedReviews));
        }
    }
}

function* deleteReview(action: ReviewUpdateAction): SagaIterator {
    yield call(callApi, {
        endpoint: {
            url: `users/reviews/${(action.payload as Review).id}`,
            method: 'DELETE',
        },
        onSuccess: (_res): ReviewUpdateAction => createDeleteReviewSuccessAction(action.payload as Review),
        onError: createAddReviewErrorAction,
    });
}

function* onReviewDeleted(action: ReviewUpdateAction): SagaIterator {
    const payload = action.payload as Review;

    const movie: MovieStateData | undefined = yield select(
        (state: AppState): MovieStateData | undefined => state.movie.data
    );

    if (movie) {
        const payload = action.payload as Review;
        yield put(
            createFetchMovieSuccessAction({
                ...movie,
                reviews: filter(movie.reviews, (review: Review): boolean => review.id !== payload.id),
            })
        );
    }

    const lastReviews: ReviewsState = yield select((state: AppState): ReviewsState => state.reviews);

    yield put(
        createFetchLastReviewsSuccessAction(
            filter(lastReviews.data || [], (review: Review): boolean => review.id !== payload.id)
        )
    );

    yield put(createToggleConfirmReviewDeletionModalAction());
}

export default function* ReviewsSaga(): SagaIterator {
    yield takeLatest(ReviewsActionType.FETCH_LAST_REVIEWS, fetchLastReviews);
    yield takeLatest(ReviewsActionType.FETCH_USER_REVIEWS, fetchUserReviews);
    yield takeLatest(ReviewsActionType.ADD_REVIEW, addReview);
    yield takeLatest(ReviewsActionType.UPDATE_REVIEW, updateReview);
    yield takeLatest(ReviewsActionType.DELETE_REVIEW, deleteReview);
    yield takeLatest(ReviewsActionType.DELETE_REVIEW_SUCCESS, onReviewDeleted);
    yield takeLatest(ReviewsActionType.ADD_REVIEW_SUCCESS, onReviewAddedSuccess);
}
