export enum ReviewsActionType {
    FETCH_USER_REVIEWS = 'REVIEWS/FETCH_FROM_USER',
    FETCH_USER_REVIEWS_SUCCESS = 'REVIEWS/FETCH_FROM_USER_SUCCESS',
    FETCH_USER_REVIEWS_ERROR = 'REVIEWS/FETCH_FROM_USER_ERROR',

    FETCH_LAST_REVIEWS = 'REVIEWS/FETCH_LAST',
    FETCH_LAST_REVIEWS_SUCCESS = 'REVIEWS/FETCH_LAST_SUCCESS',
    FETCH_LAST_REVIEWS_ERROR = 'REVIEWS/FETCH_LAST_ERROR',

    ADD_REVIEW = 'REVIEWS/ADD',
    UPDATE_REVIEW = 'REVIEWS/UPDATE',
    ADD_REVIEW_SUCCESS = 'REVIEWS/ADD_SUCCESS',
    ADD_REVIEW_ERROR = 'REVIEWS/ADD_ERROR',
    EDIT_REVIEW = 'REVIEWS/EDIT',
    RESET_ADD_REVIEW_SUCCESS = 'REVIEWS/RESET_ADD_SUCCESS',

    DELETE_REVIEW = 'REVIEWS/DELETE',
    DELETE_REVIEW_SUCCESS = 'REVIEWS/DELETE_SUCCESS',
}
