import { ReviewsAction, ReviewsFetchAction, ReviewUpdateAction } from './ReviewsActions';
import { ReviewsActionType } from './ReviewsActionType';
import { Review } from '../../domain/community';
import { FetchState } from '../Fetch';
import { RequestError } from '../../sagas/APICallSaga';

export interface ReviewsState extends FetchState<Review[]> {
    successfullyAdded: boolean;
    reviewToEdit?: Review;
}

const initialState: ReviewsState = {
    successfullyAdded: false,
    isLoading: false,
};

export const reviewsReducer = (state: ReviewsState = initialState, action: ReviewsAction): ReviewsState => {
    switch (action.type) {
        case ReviewsActionType.FETCH_LAST_REVIEWS:
        case ReviewsActionType.FETCH_USER_REVIEWS:
            return {
                ...state,
                isLoading: true,
            };

        case ReviewsActionType.FETCH_LAST_REVIEWS_SUCCESS:
        case ReviewsActionType.FETCH_USER_REVIEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                data: (action as ReviewsFetchAction).payload as Review[],
            };

        case ReviewsActionType.FETCH_USER_REVIEWS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: (action as ReviewsFetchAction).payload as RequestError,
            };

        case ReviewsActionType.EDIT_REVIEW:
            return {
                ...state,
                reviewToEdit: (action as ReviewUpdateAction).payload as Review,
            };

        case ReviewsActionType.ADD_REVIEW_SUCCESS:
            return {
                ...state,
                successfullyAdded: true,
                reviewToEdit: undefined,
            };

        case ReviewsActionType.DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                reviewToEdit: undefined,
            };

        case ReviewsActionType.RESET_ADD_REVIEW_SUCCESS:
            return {
                ...state,
                successfullyAdded: false,
            };

        default:
            return state;
    }
};
